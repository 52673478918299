import { graphql, useStaticQuery } from "gatsby"

const FullData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: postDate, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            postDate(formatString: "MMMM Do, YYYY")
            postImage {
              fluid(maxWidth: 500) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  return data;
}

export default FullData
