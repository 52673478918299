import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import FadeIn from "../animations/fadeIn"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  card: {
    marginBottom: 15,
    borderRadius: 0,
  },
})

const CardLink = styled(Link)`
  width: 100%;
  padding: 18px;
  font-size: 1.1rem;
  color: white;
  text-decoration: none;
`

const CardFooter = styled(CardActions)`
  && {
    padding: 0;
    background: rgba(0, 0, 0, 0.87);
    text-align: center;

    &:hover {
      background: #d1d1d1;
      background: rgba(0, 0, 0, 0.8);
    }
  }
`

const BlogItem = ({
  compact = false,
  animate = true,
  postImage,
  postImageTitle,
  postTitle,
  postDate,
  slug,
  id,
}) => {
  const classes = useStyles()
  let media = null
  console.log(postImage)
  console.log(postTitle)
  console.log(postDate)
  if (!compact) {
    media = (
      <CardMedia
        className={classes.media}
        image={postImage}
        title={postImageTitle}
        alt={postImageTitle}
      />
    )
  }

  return (
    <Grid xs={12} sm={6} md={4} item key={id}>
      <FadeIn animate={animate} thresh={0}>
        <Card className={classes.card} elevation={2}>
          {media}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {postTitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {postDate}
            </Typography>
          </CardContent>
          <CardFooter>
            <CardLink to={`/blog/${slug}`} size="large">
              Read More
            </CardLink>
          </CardFooter>
        </Card>
      </FadeIn>
    </Grid>
  )
}

export default BlogItem
