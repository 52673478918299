import { graphql, useStaticQuery } from "gatsby"

const CompactData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: postDate, order: DESC }, limit: 3) {
        edges {
          node {
            id
            title
            slug
            postDate(formatString: "MMMM Do, YYYY")
            postImage {
              fluid(maxWidth: 500) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  return data;
}

export default CompactData
