
import React from "react"
import Typography from "@material-ui/core/Typography"

const Paragraph = ({ children, className, variant="body1" }) => {
    return (
        <Typography variant={variant} paragraph={true} className={className}>
          { children }
        </Typography>
    )
}

export default Paragraph;