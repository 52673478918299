import React from "react"
import Box from "@material-ui/core/Box"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const SpaceBox = ({ children }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  let space = matches ? "4rem" : "6rem"
  return <Box my={space}>{children}</Box>
}

export default SpaceBox
