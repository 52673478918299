import React from "react"
import Grid from "@material-ui/core/Grid"
import Paragraph from "../components/elements/paragraph"
import Layout from "../components/pageSections/layout"
import BlogList from "../components/blog/blogList"
import SEO from "../components/pageSections/seo"

const BlogPage = () => {
  return (
    <Layout light={true}>
      <SEO title="Blog" description="The ramblings of a developer with an interest in React, Drupal, PHP and JavaScript. Some of it is even technical!" />
      <BlogList>
        <Grid component="section" item xs={12} md={8}>
          <Paragraph>
            As I continue exploring new technologies, ways of working and
            anything else that seems reasonably interesting I'll keep updating.
          </Paragraph>
        </Grid>
      </BlogList>
    </Layout>
  )
}

export default BlogPage
