import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import FadeIn from "../animations/fadeIn"
import SpaceBox from "../elements/spaceBox"
import BlogItem from "./blogItem"
import CompactData from "./compactBlogList"
import FullData from "./fullBlogList"

const BlogList = ({ children, compact = false, animate = true }) => {
  let content
  if (!compact) {
    content = FullData()
  } else {
    content = CompactData()
  }

  return (
    <SpaceBox>
      <Grid xs={12} container item>
        <FadeIn animate={animate}>
          <Typography variant="h2" gutterBottom>
            Blog posts
          </Typography>
        </FadeIn>
      </Grid>

      {children ? (
        <Box mb={"1rem"}>
          <FadeIn animate={animate}>
            <Grid xs={12} container item>
              {children}
            </Grid>
          </FadeIn>
        </Box>
      ) : null}
      <Grid container spacing={2}>
        {content.allContentfulBlogPost.edges.map(edge => {
          return (
            <BlogItem
              compact={compact}
              animate={animate}
              postImage={edge.node.postImage.fluid.src}
              postImageTitle={edge.node.postImage.title}
              postTitle={edge.node.title}
              postDate={edge.node.postDate}
              slug={edge.node.slug}
              id={edge.node.id}
            />
          )
        })}
      </Grid>
    </SpaceBox>
  )
}

export default BlogList
